import React from 'react'
import './footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const history = useNavigate();

  return (
    <div>
      <footer className="footer">
        <a href="https://www.instagram.com/BeautybyBeza/" className='instagram social'><FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <div className='footerflex'>
          <p className='termsinfooter' onClick={() => history('/terms')}>Policies</p>
          <p> | ©2022beautybybeza</p>
        </div>
      </footer>
    </div>
  )
}

export default React.memo(Footer);

export const photos = [

  {
    id: 7,
    src: 'konjoone.jpeg',
    width: 3,
    height: 4
  },
  {
    id: 2,
    src: 'img1.JPG',
    width: 3,
    height: 4
  },
  {
    id: 1,
    src: 'konjotwo.jpeg',
    width: 3,
    height: 4
  },
  {
    id: 3,
    src: 'img12.JPG',
    width: 3,
    height: 4
  },
  {
    id: 4,
    src: 'img5.JPEG',
    width: 3,
    height: 4
  },
  {
    id: 5,
    src: 'img13.JPG',
    width: 3,
    height: 4
  },
  {
    id: 6,
    src: 'img16.JPG',
    width: 3,
    height: 4
  },
  {
    id: 8,
    src: 'img15.JPG',
    width: 3,
    height: 4
  },

  {
    id: 9,
    src: 'img18.jpg',
    width: 3,
    height: 4
  },

  {
    id: 10,
    src: 'img17.jpg',
    width: 3,
    height: 4
  },
  {
    id: 11,
    src: 'img7.JPG',
    width: 3,
    height: 4
  },
  {
    id: 15,
    src: 'img14.jpeg',
    width: 3,
    height: 4
  },
  {
    id: 12,
    src: 'img11.JPG',
    width: 3,
    height: 4
  },
  {
    id: 13,
    src: 'img9.jpg',
    width: 3,
    height: 5
  },

  {
    id: 14,
    src: 'img3.jpg',
    width: 3,
    height: 4
  },
  {
    id: 16,
    src: 'img6.JPG',
    width: 3,
    height: 4
  },
  {
    id: 17,
    src: 'hana.jpg',

    width: 3,
    height: 4
  },
  {
    id: 18,
    src: 'makeup1.jpeg',
    width: 3,
    height: 4
  },
];
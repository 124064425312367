import React, { useState, useCallback } from 'react'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../photo/Photo";
import './portfolio.css'

const Portfolio = () => {
  const [curImg, setCurImg] = useState(0);
  const [openPic, setOpenPic] = useState(false);

  const openSinglePic = useCallback((event, { photo, index }) => {
    setCurImg(index);
    setOpenPic(true);
  }, []);

  const closeSinglePic = () => {
    setCurImg(0);
    setOpenPic(false);
  };
  return (
    <div>

      <Gallery photos={photos} onClick={openSinglePic} />
      <ModalGateway>
        {openPic ? (
          <Modal onClose={closeSinglePic}>
            <Carousel
              currentIndex={curImg}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default Portfolio
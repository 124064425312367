import React, { useEffect, useState } from "react";
import Input from "../Input";
import Checkbox from "../Checkbox";
import * as yup from "yup";
import emailjs from '@emailjs/browser';
import "./ContactForm.css";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { init } from '@emailjs/browser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"


init(process.env.REACT_APP_YOUR_USER_ID);
const YOUR_SERVICE_ID = process.env.REACT_APP_YOUR_SERVICE_ID;
const YOUR_TEMPLATE_ID = process.env.REACT_APP_YOUR_TEMPLATE_ID;

const ContactForm = () => {
    const navigate = useNavigate()
    const defaultState = {
        fullName: "",
        email: "",
        message: "",
        phoneNumber: "",
        naturalGlam: "",
        eventGlam: "",
        bridal: "",
        makeupLesson: ""
    }

    const [formState, setFormState] = useState(defaultState);
    const [error, setError] = useState({ ...defaultState })
    const [disabledButton, setDisabledButton] = useState(true)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const formSchema = yup.object().shape({
        fullName: yup.string().required("Please enter your first name").min(2, "This is not your name"),
        email: yup.string().email().required("Please enter your email"),
        phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        message: yup.string().notRequired(),
        naturalGlam: yup.string().notRequired(),
        eventGlam: yup.string().notRequired(),
        bridal: yup.string().notRequired(),
        makeupLesson: yup.string().notRequired(),


    })
    useEffect(() => {
        formSchema.isValid(formState)
            .then(valid => {
                setDisabledButton(!valid)
            })
    }, [formState, formSchema])


    const inputChange = event => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        if (event.target.type === "checkbox") {
            setFormState({
                ...formState, [event.target.name]: event.target.value
            })

        }
        else {
            setFormState({
                ...formState, [event.target.name]: event.target.value
            })
        }
        yup.reach(formSchema, event.target.name)
            .validate(value)
            .then(
                valid => {
                    setError({ ...error, [event.target.name]: "" })
                }
            )
            .catch(
                err => {
                    setError({ ...error, [event.target.name]: err.errors[0] })
                }
            )
    }

    const submitForm = event => {
        event.preventDefault();
        var templateParams = {
            name: formState.fullName,
            email: formState.email,
            phoneNumber: formState.phoneNumber,
            message: formState.message,
            naturalGlam: formState.naturalGlam,
            eventGlam: formState.eventGlam,
            bridal: formState.bridal,
            makeupLesson: formState.makeupLesson
        };
        emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams)
            .then(function (response) {
                if (response.status === 200) {
                    navigate("/success");
                }
            }, function (error) {
                console.log('FAILED...', error);
            });
    }

    return (
        <>
            <Container>
                <Row className='mb-5 mt-3'>
                    <Col lg="8">
                        <h1 className="display-4 mb-4">
                            Contact Me
                        </h1>
                    </Col>
                </Row>
                <Row className="sec_sp">
                    <Col lg="5" className='mb-5'>
                        <h4 className="color sec py-4">Reach me at</h4>
                        <address>
                            <strong>Email: <a className="mailto" href="mailto:beautybybeza1@gmail.com">beautybybeza1@gmail.com</a></strong>
                            <br />
                            <br />
                            <strong>Phone: <a className="mailto" href="tel:+1-253-341-9203">253-341-9203</a></strong>
                            <br />
                            <br />
                            <strong onClick={() => window.open("https://www.instagram.com/BeautybyBeza/", "_blank")}>Instagram: <FontAwesomeIcon icon={faInstagram} size="1x" color="#d6249f" cursor="pointer" /></strong>

                        </address>
                        <p>Please take the time to fill out the information to request an appointment.</p>
                    </Col>
                    <Col lg="7" className="d-flex align-item-center">
                        <Form>
                            <Input className="form-control rounded-0" label={"Full Name"} name="fullName" tabIndex="1" value={defaultState.fullName} onChange={inputChange} errors={error} type="text" />
                            <br />
                            <Input className="form-control rounded-0" label={"Email Address"} name="email" tabIndex="2" value={defaultState.email} onChange={inputChange} errors={error} type="text" />
                            <br />
                            <Input label={"Phone Number:"} name="phoneNumber" tabIndex="3" value={defaultState.phoneNumber} onChange={inputChange} errors={error} />
                            <br />
                            <Form.Label>Message:</Form.Label>

                            <Input className="form-control rounded-0" name="message" tabIndex="4" value={defaultState.message} onChange={inputChange} errors={error} />
                            <br />
                            <Row>
                                {/* Bride:$175 - Trial: $95
 */}
                                <Col lg="6" className='form-group'>
                                    <Form.Label> Please select the service</Form.Label>
                                    <Checkbox tabIndex="6" labelName={"Natural Glam - $125 - Glowy Skin: effortless soft contouring, neutral colors on the eyes and lips."} name="naturalGlam" value={"Natural Glam - $125 - Glowy Skin: effortless soft contouring, neutral colors on the eyes and lips."} onChange={inputChange} />
                                    <Checkbox tabIndex="7" labelName={"Full Glam/ Event Glam - $150 - Matte or Glowy Skin: chiseled cheeks & dazzling highlight, multicolored eyeshadow or glitters paired with a natural or bold lip."} value={"Full Glam/ Event Glam - $150 - Matte or Glowy Skin: chiseled cheeks & dazzling highlight, multicolored eyeshadow or glitters paired with a natural or bold lip."} name="eventGlam" onChange={inputChange} />
                                    <Checkbox tabIndex="8" labelName={"Bride: - $175 - Bridal Party: - $120 - Trial:- $95 -. Email for a wedding/ event consultation."} value={"Bridal - $175 & Up"} name={"bridal"} onChange={inputChange} />
                                    <Checkbox tabIndex="9" labelName={"Makeup Lessons - Email for inquiry Travel fee is added for any distance outside of Los Angeles County.I travel nationwide and internationally"} value={"Makeup Lessons - Email for inquiry Travel fee is added for any distance outside of Los Angeles County.I travel nationwide and internationally"} onChange={inputChange} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="12" className="form-group">
                                    <button className="btn ac_btn" type="submit" disabled={disabledButton} onClick={submitForm} >Send</button>
                                </Col>
                            </Row>

                        </Form>

                    </Col>
                </Row>
                <br />
            </Container>

          
        </>
    )
}

export default ContactForm;
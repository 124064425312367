import React from 'react'
import './service.css'
import Image33 from '../image/model22.jpeg'
import wedding from '../image/mekupwed.png'
import lesson from '../image/learntodo.jpeg'
import learn from '../image/IMG_3419.jpg'
import { useNavigate } from 'react-router-dom';



const Service = () => {
  const history = useNavigate();

  return (
    <div className="homepage">
      <div className="items-list-wrapper">
        <div className="header">
          <h4>Service & Pricing</h4>
        </div>
        <div className="item-card cardEffect">
          <img className="item-list-image" src={wedding} alt={'me'} />
          <br />
          <p><br />
            Bride:$175 - Trial: $95<br />
            Bridal party: $120<br />
          </p>
          <button className="serviceBtn" onClick={() => history('/contact')}>BOOK NOW</button>
          <div className="info">
            <p>
              {' '}
              <br />
              Email for a wedding/ event consultation.
            </p>
          </div>
        </div>
        <div className="item-card cardEffect">
          <img className="item-list-image" src={Image33} alt={Image33} />
          <p> <br />Full Glam-Event<br /> $150 </p>
          <button className="serviceBtn" onClick={() => history('/contact')}>BOOK NOW</button>
          <div className="info">
            <p>
              {' '}
              <br />
              Matte or Glowy Skin: chiseled cheeks & dazzling highlight,
              multicolored eyeshadow or glitters paired with a natural or bold
              lip.
            </p>
          </div>
        </div>
        <div className="item-card cardEffect">
          <img className="item-list-image" src={learn} alt={Image33} />
          <p>
            <br />
            Natural Glam
            <br /> $125{' '}
          </p>
          <button className="serviceBtn" onClick={() => history('/contact')}>BOOK NOW</button>

          <div className="info">
            <p>
              {' '}
              <br />
              Glowy Skin: effortless soft contouring, neutral colors on the eyes
              and lips.
            </p>
          </div>
        </div>

        <div className="item-card cardEffect">
          <img className="item-list-image " src={lesson} alt={Image33} />
          <p> <br />Makeup Lessons <br /> -$- </p>

          <button className="serviceBtn" onClick={() => history('/contact')}>BOOK NOW</button>
          <div className="info">
            <br />
            <p>Email for inquiry</p>
          </div>
        </div>
      </div>
     

      <br />
    
    </div>
  )
}

export default Service

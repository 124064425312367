import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Link, Routes, Route } from "react-router-dom";
import LandingPage from '../landingpage/LandingPage'
import About from '../about/About'
import Portfolio from "../portfolio/Portfolio";
import Service from '../service/Service';
import ContactForm from '../ContactForm/ContactForm';
import './navbar.css';
import Success from '../success/Success';
import Terms from "../terms/Terms";
import ScrollToTop from '../ScrollToTop'

const NavBar = () => {
  ScrollToTop();
  return (
    <div>
      <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark" style={{ width: '100%' }}>
        <Container style={{ positio: 'fixed' }}>
          <Navbar.Brand className="logo" href="/"><strong>BEAUTY</strong>byBeza</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="ms-auto" >
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/portfolio">Portfolio</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/service">Service & Pricing</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>

        <Route path="/terms" element={<Terms />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path='/' element={<LandingPage />} />
        <Route path="/success" element={<Success />} />

      </Routes>

    </div>
  )
}

export default NavBar;

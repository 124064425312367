import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './Components/navbar/NavBar'
import Footer from './Components/footer/Footer'


function App() {
  return (
    <div>
      <NavBar/>
      <Footer/>
    </div>
  )
}

export default App;

import React from "react";
import { Col, Row, Form } from 'react-bootstrap';

const Input = (props) => {
    const errorMessage = props.errors[props.name]
    return (
        <>
            <Row>
                <Form.Label>{props.label}</Form.Label>
                <Col lg="6" className='form-group'>
                    <input className="form-control rounded-0" type={props.type} name={props.name} tabIndex={props.tabIndex} onChange={props.onChange} />
                </Col>
            </Row>
            {errorMessage.length !== 0 && <p className="error">{errorMessage}</p>}
        </>
    )
}

export default Input;
import React from 'react'
import Image from '../image/model2.jpeg'
import Image33 from '../image/model44.jpeg'
import liz from '../image/wed.png'
import './landingpage.css'
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const history = useNavigate();

  return (
    <div className="homepage">
      <div className="items-list-wrapper">
        <div className="header">
          <h1><strong>BEAUTY</strong> BY BEZA</h1>
          <p>MakeUp Artist </p>
        </div>
        <div className="item-card cardEffect" onClick={() => history('/service')}>
          <img className="item-list-image" src={liz} alt={'me'} />
          <p><br />Bridal & Engagement</p>
        </div>

        <div className="item-card cardEffect" onClick={() => history('/service')}>
          <img className="item-list-image" src={Image} alt={Image33} />
          <p> <br />Fashion</p>
        </div>

        <div className="item-card cardEffect" onClick={() => history('/service')}>
          <img className="item-list-image" src={Image33} alt={Image33} />
          <p> <br />Beauty</p>
        </div>
        <div className="btnBox">
          <button className="btn" onClick={() => history('/contact')}>BOOK NOW</button>
        </div>
      </div>
      <div className="hero-image">
      </div>

    </div>
  )
}

export default LandingPage

import React from 'react'
import "./success.css";

const Success = () => {
  return (
    <div className='successPage'>
      <div className='success'>

        <h1> Thank you for getting in touch! </h1>


        <p>We appreciate you contacting Beauty by Beza. Will get back in touch with you soon! Have a great day!</p>

        <br />
      </div>
    </div>
  )
}

export default Success

import React from "react";



const Checkbox = (props) => {
    return (
        <>
            <div className="form-check">
                <input type="checkbox" name={props.name} value={props.value} id="flexCheckDefault" tabIndex={props.tabIndex} onChange={props.onChange} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    {props.labelName}
                </label>
            </div>

        </>
    )
}

export default Checkbox;
import React from 'react'
import './about.css';
import clientPhoto from "../image/bezamakeup.jpeg";
import { useNavigate } from 'react-router-dom';


const About = () => {


  const history = useNavigate();

  return (
    <div className='sec'>
      <div section="about">
        <div className="max-width">
          <div className="aboutContect">
            <div className="left">
              <img src={clientPhoto} alt="me" />
            </div>
            <div className="right">
              <p>
                <strong className='beza'>Beza</strong> started her career as an artist at MAC cosmetics, where she founded her business, BeautybyBeza. Empowering each individual to feel beautiful and confident is central to BeautyByBeza's mission. Experienced in working with diverse facial features, skin complexions, and textures, BeautyByBeza specializes in makeup for all people and occasions. As you already know, you are beautiful. BeautyByBeza looks forward to complementing your natural beauty through personalized makeup for your next event while providing you with the best overall experience.
              </p>
            </div>
          </div>
        </div>


      </div>
      <br />
      <div className="btnBox">
        <button className="btn" onClick={() => history('/contact')}>CONTACT ME</button>
      </div>
      <div className="heroabout">
      </div>
    </div>
  )
}

export default About
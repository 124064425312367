import React from "react";
import './Terms.css';

const Terms = () => {
    return (
        <div>
            <div className="termpage">
                <div className="termHeader"><h3>TERMS & CONDITIONS</h3></div>

                <div className="dispOne">
                    <div className="divOne">
                        <h4>BOOKING</h4>
                        <p>Please contact me to book your makeup appointment.</p>
                        <p>A non refundable deposit will be required to book and secure your services.</p>
                        <p>A deposit and signed contract are required to book and secure services for parties booking 5 and more services.</p>
                        <p>Appointments before 6AM and after 9PM incur a $25 (fee subject to changes).</p>
                        <p>Services outside of Los Angeles is required a travel fee (based on location).</p>
                        
                        <h5> Before your appointments</h5>
                        <ul>
                            <li>Please arrive on time to your appointment.</li>
                            <li>Come with a clean, makeup-free face.</li>
                            <li>Please have inspiration photos and videos.</li>
                        </ul>
                    </div>
                    <div className="divOne">
                        <h4>RESCHEDULING / CANCELLATION / NO SHOW</h4>
                        <p>Service Cancellations less than  15 Days from booked service time will lose deposit.</p>
                        <p>Service Reschedules less than 24  hours will incur a $40 rescheduling fee.</p>
                    </div>
                </div>

                <div className="divOne">

                    <h4>ADD ON SERVICES</h4>
                    <p>Add On Services are requested to be booked at the time of booking.</p>
<p>Any extra services will be charged at the full rate and serviced after all the pre-booked services are completed.
</p>                
</div>

                <div className="divOne">
                    <h4>Out of state services</h4>
                    <ul>
                        <li> Three months out booking recommended.</li>
                        <li> Airfare & accommodation must be covered for the artist (it will be discussed otherwise).</li>
                        <li>Transportation to the airport, hotel, and the event must be covered.  </li>
                        <li>Cancellation less than 30 days will lose deposit.  </li>
                    </ul>
                </div>
                <div className="divOne">

                    <h4>SICKNESS OR FAMILY EMERGENCY  </h4>
                    <p>If you, or another person in your household has become ill or sick please contact us as soon as possible to reschedule your appointment for a later date. For your safety and ours, please do not come to your appointment sick. If you arrive and we deem your condition as ill or sick, your appointment may be cut short/canceled/rescheduled for a later date. A one-time allowance of a last minute cancellation or reschedule will be permitted for sudden sickness or family emergency. After that, the cancellation and no show policy is in effect.
</p>
                    <p>Please cancel if you have pink eye, open sores, etc.</p>
                </div>
                <div className="divOne">
                    <h4>PAYMENT</h4>
                    <p>Deposit and final payment is only accepted in Cash, CashApp, Venmo, Zelle, and Apple pay.</p>
                    <p>Cash is preferred only in exact payment.</p>
                </div>
                <div className="divOne">

                    <h4>REFUNDS</h4>
                    <p>There are no refunds on any services that have been performed. If you are unhappy with any aspect of your makeup, alert us to it during your appointment so we can adjust to your liking.</p>
                </div>
                <div className="divOne">

                    <h4>ALLERGIES</h4>
                    <p>Please alert us of any allergies before the start of your service.</p>
                </div>
                <br />
                <p>* Parking & Hotel Parking Fees – You will be responsible for any parking fees for artist(s).  This can be billed to your hotel room or added to your bill on the day of. Valet is expected when available.</p>
                <div className="agree">By Booking Beauty by Beza Services you agree to these policies.</div>
                <div className="thankyou">THANK YOU FOR CHOOSING BEAUTY BY BEZA!!</div>
            </div>
        </div>
    )
}
export default Terms